<template lang="pug">
mixin close-button-input-form
  template(v-if="!showAdvanced")
    .border-top-h3.bt-0.mb-1(:class="{ 'mt-4': isTeaserPage }")
      template(v-if="!isTeaserPage")
        om-switches(
          label="closeOnEsc"
          property="data.closeGestures.onEsc"
          :useNested="true"
          :typeOfComponent="typeOfComponent"
        )
        om-switches(
          v-if="!isNano"
          label="closeOnOverlayClick"
          property="data.closeGestures.onOverlayClick"
          :useNested="true"
          :typeOfComponent="typeOfComponent"
        )
        om-device-chooser(
          v-if="data.closeGestures.onOverlayClick && !isSidebar"
          property="data.closeGestures.onOverlayClickDevice"
          :useNested="true"
          defaultValue="mobile"
          :typeOfComponent="typeOfComponent"
        )
      om-color-input(
        v-show="isTeaserPage"
        label="backgroundColor"
        :property="`${getCloseButtonPath}.background.color`"
        :typeOfComponent="typeOfComponent"
      )
      om-color-input(
        v-show="isTeaserPage"
        label="xColor"
        :property="`${getCloseButtonPath}.color`"
        :typeOfComponent="typeOfComponent"
      )
      om-color-input(
        v-show="!isTeaserPage"
        label="backgroundColor"
        :property="`${getCloseButtonPath}.background.color`"
        :typeOfComponent="typeOfComponent"
      )
      om-color-input(
        v-show="!isTeaserPage"
        label="xColor"
        :property="`${getCloseButtonPath}.color`"
        :typeOfComponent="typeOfComponent"
      )
      font-dropdown(
        label="fontFamily"
        :property="`${getCloseButtonPath}.fontFamily`"
        :i18n="false"
      )
      om-range-input(
        :property="`${getCloseButtonPath}.fontSize`"
        label="size"
        typeOfComponent="global"
        :min="8"
        :max="70"
        :step="1"
      )
      om-font-awesome-button(
        v-show="isTeaserPage"
        label="style"
        property=""
        subPath="globalStyle.teaserCloseButton"
      )
      om-font-awesome-button(
        v-show="!isTeaserPage"
        label="style"
        property=""
        subPath="globalStyle.closeButton"
      )
    om-range-input(
      label="borderRadius"
      :property="`${getCloseButtonPath}.borderRadius`"
      :min="0"
      :max="50"
      :step="1"
      unit="px"
      :typeOfComponent="typeOfComponent"
    )
  template(v-else)
    .mt-3
      template(v-if="!isTeaserPage")
        om-range-input(
          :editMobile="true"
          label="verticalAlignment"
          property="globalStyle.closeButton.$device.top"
          :min="-50"
          :max="50"
          :step="1"
          unit="px"
        )
        om-range-input(
          :editMobile="true"
          label="horizontalAlignment"
          property="globalStyle.closeButton.$device.right"
          :min="-50"
          :max="50"
          :step="1"
          unit="px"
        )
        dropdown(
          label="visibilityDelay"
          property="globalStyle.closeButton.delay"
          :options="visibilityOptions"
          :horizontal="true"
        )
      template(v-else)
        om-margin(
          :showAccordion="false"
          propsProperty=""
          :subPath="`${getCloseButtonPath}.margin`"
          typeOfComponent="canvas"
          :addDeviceSelector="false"
        )
div
  +close-button-input-form
</template>

<script>
  import FontDropdown from '@/editor/components/sidebar/inputs/FontDropdown.vue';
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      FontDropdown,
      Dropdown: () => import('@/editor/components/sidebar/components/Dropdown.vue'),
    },
    mixins: [itemMixin],
    props: {
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: '.style.' },
      typeOfComponent: { type: String, default: 'global' },
      showAdvanced: { type: Boolean, default: false },
    },
    computed: {
      ...mapState(['openCloseButton', 'globalStyle', 'data', 'mobilePreview']),
      ...mapGetters(['nestedAccess', 'isNano', 'isSidebar', 'isTeaserPage']),
      device() {
        return this.mobilePreview ? 'mobile' : 'desktop';
      },
      isHu() {
        return this.$i18n.locale === 'hu';
      },
      visibilityOptions() {
        const postfix = this.isHu ? 'mp' : 'sec';
        const options = new Array(21)
          .fill('')
          .map((_, value) => ({ key: `${value} ${postfix}`, value }));
        return options;
      },
      getCloseButtonPath() {
        if (this.isTeaserPage) {
          return `globalStyle.teaserCloseButton.${this.device}`;
        }
        return `globalStyle.closeButton.${this.device}`;
      },
    },
    watch: {
      isSidebar(n) {
        if (n) {
          this.$store.state.data.closeGestures.onOverlayClickDevice = 'mobile';
        }
      },
    },
    methods: {
      ...mapMutations(['setStateAttr', 'updateData']),
      accordionClosed(v) {
        if (v === false) {
          this.setStateAttr({ attr: 'openCloseButton', value: false });
        }
      },
    },
  };
</script>
