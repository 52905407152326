<template lang="pug">
.backdrop(v-if="colorPicker.show")
  .editor-color-picker(ref="picker" @click.stop="" :style="pickerStyle")
    template(v-if="colorPicker.colorInstance")
      sketch-editor-v2(
        :value="sketchColors"
        :disableAlpha="!colorPicker.alpha"
        :editMobile="true"
        :colorInstance="colorPicker.colorInstance"
      )
    template(v-else)
      sketch-editor(
        :value="sketchColors"
        :disableAlpha="!colorPicker.alpha"
        :editMobile="true"
        @change="setHeight"
      )
</template>
<script>
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import SketchEditor from '@/editor/components/color/components/Sketch.vue';
  import SketchEditorV2 from '@/editor/components/color/components/SketchV2.vue';
  import { mapState } from 'vuex';

  const SPACING_MIN = 10;
  const WIDTH_PICKER_SWATCH = 128;
  const LEFT_MENU_WIDTH = 72;
  const LEFT_PANE_SWATCH_HEIGHT = 32;

  export default {
    components: {
      SketchEditor,
      SketchEditorV2,
    },
    mixins: [themeColorsMixin],
    data: () => ({
      pickerHeight: 0,
      pickerWidth: 0,
      opacity: 0,
      firstRender: true,
    }),
    computed: {
      ...mapState(['mobilePreview']),
      sketchColors() {
        return { hex: this.paletteColor.color };
      },
      colorPicker() {
        return this.$store.state.colorPicker;
      },
      paletteColor() {
        let { value, property } = this.colorPicker;

        if (!property.startsWith('omcolor') && !value) {
          value = property.endsWith('olor') ? 0 : 1;
        }

        if (value > -1) {
          return {
            color: this.getColors[value],
            index: value,
          };
        }

        return { color: value };
      },
      isGlobalProp() {
        return this.colorPicker.property.includes('globalStyle');
      },
      elementTop() {
        return Number(this.colorPicker.top.replace('px', ''));
      },
      elementLeft() {
        return Number(this.colorPicker.left.replace('px', ''));
      },
      topPosition() {
        const viewHeight = window.innerHeight;
        let endPosition = this.elementTop + this.pickerHeight;
        let top = this.elementTop;

        if (this.isGlobalProp) {
          const previewAndSpacing = LEFT_PANE_SWATCH_HEIGHT + SPACING_MIN;
          top += previewAndSpacing;
          endPosition += previewAndSpacing;
        }

        return viewHeight > endPosition ? `${top}px` : null;
      },
      bottomPosition() {
        const viewHeight = window.innerHeight;
        const endPosition = this.elementTop + this.pickerHeight;

        return viewHeight < endPosition ? `${SPACING_MIN}px` : null;
      },
      leftPosition() {
        let left = this.elementLeft - this.pickerWidth - SPACING_MIN;

        if (left < SPACING_MIN) {
          left = this.elementLeft + WIDTH_PICKER_SWATCH + SPACING_MIN;
        }

        if (this.isGlobalProp) {
          left = LEFT_MENU_WIDTH + SPACING_MIN;
        }

        if (this.colorPicker.property === 'omcolor') {
          left = this.elementLeft;
        }

        const endPosition = left + this.pickerWidth;
        const maxEnd = window.innerWidth - SPACING_MIN;
        if (endPosition > maxEnd) {
          left = maxEnd - this.pickerWidth;
        }

        return `${left}px`;
      },
      pickerStyle() {
        return {
          top: this.topPosition,
          bottom: this.bottomPosition,
          left: this.leftPosition,
          opacity: this.opacity,
        };
      },
    },
    watch: {
      'colorPicker.show': function (show) {
        if (show) {
          this.opacity = 0;
          this.setHeight();
        }
      },
    },
    mounted() {
      this.$bus.$on('rebuild-color-instance', this.setHeight);
    },
    beforeDestroy() {
      this.$bus.$off('rebuild-color-instance', this.setHeight);
    },
    methods: {
      setHeight() {
        setTimeout(() => {
          this.pickerHeight = this.$refs.picker.clientHeight;
          this.pickerWidth = this.$refs.picker.clientWidth;
          this.opacity = 1;
        }, 150);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .backdrop
    position: absolute
    height: 100%
    width: 100%
    z-index: 1000000
    left: 0
    top: 0
  .editor-color-picker
    width: 308px
    z-index: 2000
    position: absolute
    border-radius: 8px
    box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 15px 0px
    transition: opacity .1s ease-in-out
</style>
