<template lang="pug">
component(:is="component")
</template>

<script>
  import { mapGetters } from 'vuex';

  const C_V1 = 'CustomHTMLPaneV1';
  const C_V2 = 'CustomHTMLPaneV2';

  export default {
    components: {
      [C_V1]: () => import(`./${C_V1}.vue`),
      [C_V2]: () => import(`./${C_V2}.vue`),
    },
    computed: {
      ...mapGetters(['isEditorRightPaneEnabled']),
      component() {
        return this.isEditorRightPaneEnabled ? C_V2 : C_V1;
      },
    },
  };
</script>
