<template lang="pug">
modal(
  :name="name"
  :classes="modalClasses"
  :height="height"
  :width="width"
  :pivot-x="pivotX"
  :scrollable="scrollable"
  :clickToClose="clickToClose"
  :transition="transition"
  :overlayTransition="overlayTransition"
  :adaptive="adaptive"
  :maxHeight="maxHeight"
  :maxWidth="maxWidth"
  @before-open="beforeOpen"
  @opened="opened"
  @before-close="beforeClose"
  @closed="closed"
)
  .brand-modal(
    :class="{ 'brand-modal-light': color === 'light', 'brand-modal-header-border': headerBorder }"
  )
    slot(name="modal-before-header")
    .brand-modal-header(:class="{ 'brand-modal-header-gray': color === 'gray' }")
      slot(name="modal-header")
        slot(name="header-title")
          .brand-modal-title {{ title }}
        slot(name="header-subtitle")
          .brand-modal-sub-title(v-if="subtitle") {{ subtitle }}
        slot(name="header-action-icon")
          .brand-modal-action-icon
            close-icon.cursor-pointer(
              @click.native="closeClicked"
              :width="14"
              :height="14"
              color="#C2C2C2"
            )
    .brand-modal-body(:class="{ 'brand-modal-body-gray': color === 'gray' }")
      slot(name="modal-body")
    .brand-modal-footer
      slot(name="modal-footer")
</template>

<script>
  export default {
    props: {
      name: { type: String, required: true },
      height: { type: [Number, String], default: 'auto' },
      width: { type: [Number, String], default: 600 },
      maxHeight: { type: [Number, String] },
      maxWidth: { type: [Number, String] },
      scrollable: { type: Boolean, default: false },
      clickToClose: { type: Boolean, default: true },
      modalClasses: { type: String },
      transition: { type: String },
      overlayTransition: { type: String },
      pivotX: { type: Number, default: 0.5 },
      headerBorder: {
        type: Boolean,
        default: false,
      },
      title: { type: String },
      subtitle: { type: String },
      color: { type: String, default: 'light' },
      adaptive: { type: Boolean, default: false },
      addOverflowScroll: { type: Boolean, default: true },
    },

    data() {
      return {
        isOpen: false,
      };
    },

    created() {
      this.$bus.$on('hideAllModals', this.closeIfOpen);
    },

    beforeDestroy() {
      this.$bus.$off('hideAllModals', this.closeIfOpen);
    },

    methods: {
      closeClicked() {
        this.$emit('closeClicked');
        this.close();
      },

      closeIfOpen() {
        if (this.isOpen) {
          this.close();
        }
      },

      close() {
        this.$emit('close');
        this.$modal.hide(this.name);
      },

      beforeOpen(event) {
        this.$nextTick(() => {
          if (this.addOverflowScroll) document.querySelector('html').style.overflowY = 'scroll';
          document.body.classList.add('modal-open');
        });

        this.$emit('beforeOpen', event);
      },
      opened(event) {
        this.isOpen = true;
        this.$emit('opened', event);
      },
      beforeClose(event) {
        this.$nextTick(() => {
          document.body.classList.remove('modal-open');
          document.querySelector('html').style.overflowY = '';
        });

        this.$emit('beforeClose', event);
      },
      closed(event) {
        this.isOpen = false;
        this.$emit('closed', event);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .brand-modal
    &.brand-modal-header-border .brand-modal-header
      border-bottom: 1px solid #E9EFF4
</style>
